/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import { Box, Container } from "theme-ui";

import Nav from "./Nav";
import Logo from "./Logo";

const Header = () => (
	<Box
		as="header"
		sx={{
			position: ["relative", "sticky"],
			top: "0px",
			backgroundColor: "rgba(255,255,255, 1)",
			zIndex: "1000",
			// borderBottom: `solid 1px ${colors.grayMedium}`,
		}}
	>
		<Container
			px={[3, 4]}
			py={[2, 3]}
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: [
					"center",
					"space-between",
					"space-between",
					"space-between",
				],
				flexDirection: ["column", "row", "row", "row"],
			}}
		>
			<Logo
				mb={[3, 0, 0, 0]}
				sx={{ width: ["18rem", "18rem", "20rem", "22rem"] }}
			/>

			<Nav />
		</Container>
	</Box>
);

Header.propTypes = {
	siteTitle: PropTypes.string,
};

Header.defaultProps = {
	siteTitle: ``,
};

export default Header;
