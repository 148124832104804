import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import _ from "lodash";
import { Flex, Button } from "theme-ui";

const TagsMenu = (props) => {
	const [show, setShow] = useState(false);
	const data = useStaticQuery(graphql`
		query TagMenu {
			allMarkdownRemark(filter: { frontmatter: { draft: { eq: false } } }) {
				group(field: frontmatter___tags) {
					fieldValue
					totalCount
				}
			}
		}
	`);

	return (
		<Flex
			style={{
				flexDirection: "row",
				justifyContent: "flex-start",
				alignItems: "flex-top",
				flexWrap: "wrap",
			}}
			my={[2, 3]}
			{...props}
		>
			<Button
				variant="hollow"
				onClick={() => setShow(!show)}
				mr={2}
				mb={1}
				tabIndex={0}
			>
				{show ? "Hide topics" : "Show topics"}
				{/* <ToggleArrow
					arrowColor={colors.primary}
					active={show}
					label="Show Topics"
				/> */}
			</Button>
			<nav style={{ display: show ? "inline" : "none" }}>
				{data.allMarkdownRemark.group.map((tag, index) => (
					<Button
						as={Link}
						variant="subdued"
						key={index.toString()}
						mr={1}
						mb={1}
						to={`/tags/${_.kebabCase(tag.fieldValue)}`}
						tabIndex={0}
						aria-label={tag.fieldValue}
					>
						{tag.fieldValue} ({tag.totalCount})
					</Button>
				))}
			</nav>
		</Flex>
	);
};

export default TagsMenu;
