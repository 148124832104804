/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import { Global } from "@emotion/core";
import resetCSS from "../../gatsby-plugin-theme-ui/resetCSS";
import baseCSS from "../../gatsby-plugin-theme-ui/baseCSS";
import SEO from "./Seo";
import Header from "./Header";
import Footer from "./Footer";

const Layout = (props) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
					siteUrl
					description
				}
			}
		}
	`);

	return (
		<>
			<Global styles={[resetCSS, baseCSS]} />
			<SEO title={data.site.siteMetadata.title} />
			<Header siteTitle={data.site.siteMetadata.title} />

			<main>{props.children}</main>

			<Footer brand={data.site.siteMetadata.title} />
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	width: PropTypes.string,
};

export default Layout;
