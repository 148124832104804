import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Box, Input } from "theme-ui";

const Search = () => {
	const [query, setQuery] = useState(``);
	const [results, setResults] = useState([]);

	useEffect(() => {
		if (!query || !window.__LUNR__) {
			setResults([]);
			return;
		}

		const lunrIndex = window.__LUNR__["en"];
		const searchResults = lunrIndex.index.search(query, { expand: false });
		// console.log(searchResults, query, lunrIndex.store, lunrIndex.index);
		setResults(
			searchResults.map(({ ref }) => {
				return lunrIndex.store[ref];
			})
		);
	}, [query]);

	return (
		<Box className="searchWrapper">
			<Input
				type="text"
				defaultValue={query}
				onChange={(event) => {
					setQuery(event.target.value);
				}}
			/>
			<Box py={[3, 4]}>
				<p>Results:</p>

				<ul>
					{results.map((post) => (
						<li key={post.slug}>
							<Link to={post.slug}>{post.title}</Link>
						</li>
					))}
				</ul>
			</Box>
		</Box>
	);
};

export default Search;
