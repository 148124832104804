/** @jsx jsx */
import { Component, createRef } from "react";
import { jsx, Box, Button } from "theme-ui";
import { useStaticQuery, graphql } from "gatsby";
import { Twitter, Facebook, Linkedin } from "react-social-sharing";
import Social from "../data/social.json";

class CopyURL extends Component {
	constructor(props) {
		super(props);

		this.pathInput = createRef();
		this.state = {
			isCopied: false,
		};

		this.copyToClipboard = this.copyToClipboard.bind(this);
	}

	copyToClipboard() {
		const copyText = this.pathInput.current;
		copyText.select();
		document.execCommand("copy");
		this.setState(() => ({ isCopied: true }));
	}

	render() {
		const articlePath = this.props.path;

		return (
			<Box sx={{ display: "inline-block", position: "relative", top: "-3px" }}>
				<Button
					m={[2]}
					onClick={this.copyToClipboard}
					sx={{ borderRadius: "5px", lineHeight: "2" }}
				>
					{this.state.isCopied ? `URL copied!` : `Copy URL`}
				</Button>
				<input
					type="text"
					ref={this.pathInput}
					value={articlePath}
					readOnly
					style={{ width: "0px", border: "none", padding: "0" }}
				/>
			</Box>
		);
	}
}

const Sharing = ({ articleSlug, tw, fb, li }) => {
	const url = useStaticQuery(graphql`
		query siteURL {
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const path = url.site.siteMetadata.siteUrl + articleSlug;

	return (
		<Box ml={"-8px"}>
			{tw && Social.Twitter.Articles ? (
				<Twitter link={path} message={Social.Twitter.Message} />
			) : (
				""
			)}
			{fb && Social.Facebook.Articles ? (
				<Facebook link={path} message={Social.Facebook.Message} />
			) : (
				""
			)}
			{li && Social.Linkedin.Articles ? (
				<Linkedin link={path} message={Social.Linkedin.Message} />
			) : (
				""
			)}
			<CopyURL path={path} />
		</Box>
	);
};

export default Sharing;
