/** @jsx jsx */

import { Link as GatsLink } from "gatsby";
import { jsx, Box, Heading, Text, Link } from "theme-ui";

const Post = ({ post, loc, layout }) => (
	<Box
		as="article"
		sx={
			layout && loc === 0
				? {
						...PostStyling,
				  }
				: { PostStyling }
		}
	>
		<Box sx={PostImageStyling} as={GatsLink} to={post.fields.slug} mb={[2, 3]}>
			<img src={`${post.frontmatter.image}`} alt={post.frontmatter.title} />
		</Box>
		<Link as={GatsLink} to={post.fields.slug} tabIndex={0}>
			<Heading as="h4" sx={{ variant: "text.postHeading" }} mb={[1, 2]}>
				{post.frontmatter.title}
			</Heading>
		</Link>

		<Text as="p" mb={[2, 3]} variant="bodyXSmall">
			{post.frontmatter.tags.map((t, index) => (
				<span key={index.toString()}>
					{t}
					{index === post.frontmatter.tags.length - 1 ? "" : ", "}
				</span>
			))}
		</Text>
	</Box>
);

const PostStyling = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
};

const PostImageStyling = {
	width: "100%",
	height: "12em",
	overflow: "hidden",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	borderRadius: "4px",
	transition: "box-shadow 0.2s ease-out",

	"& img": {
		height: "auto",
		width: "auto",
		maxWidth: "none",
		minWidth: "100%",
		transition: "transform 0.2s ease-out",
	},

	"&:hover img": {
		transform: "scale(108%)",
	},
};

export default Post;
