/** @jsx jsx */
import { jsx } from "theme-ui";
import { Box, Container, Flex, Text, Divider } from "theme-ui";
import Nav from "./Nav";
import Logo from "../global/Logo";
import twitterLogo from "../../images/twitter.svg";
import facebookLogo from "../../images/facebook.svg";
import linkedinLogo from "../../images/linkedin.svg";
import social from "../../data/social.json";

const Footer = (props) => {
	return (
		<Box as="footer" backgroundColor="footer">
			<Container px={[3, 4]} py={[4, 5]}>
				<Divider />
				<Box pt={[3]}>
					<Logo mb={[2, 3]} />
					<Text mb={[2, 3]} variant="bodyXSmall">
						© {new Date().getFullYear()} {props.brand}
					</Text>
				</Box>

				<Flex
					sx={{
						flexDirection: ["column", "row"],
						alignItems: ["start", "center"],
						justifyContent: "space-between",
					}}
				>
					<Nav />
					<Flex
						sx={{
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						{social.Twitter.Footer && (
							<a
								href={social.Twitter.URL}
								title="twitter"
								sx={{ display: "block", width: "2em", margin: "1em" }}
							>
								<img src={twitterLogo} alt="twitter" sx={{ width: "100%" }} />
							</a>
						)}
						{social.Facebook.Footer && (
							<a
								href={social.Facebook.URL}
								title="facebook"
								sx={{ display: "block", width: "2em", margin: "1em" }}
							>
								<img src={facebookLogo} alt="facebook" sx={{ width: "100%" }} />
							</a>
						)}
						{social.Linkedin.Footer && (
							<a
								href={social.Linkedin.URL}
								title="linkedin"
								sx={{ display: "block", width: "2em", margin: "1em" }}
							>
								<img src={linkedinLogo} alt="linkedin" sx={{ width: "100%" }} />
							</a>
						)}
					</Flex>
				</Flex>
			</Container>
		</Box>
	);
};

export default Footer;
