/** @jsx jsx */
import _ from "lodash";
import { Link as GatsbyLink } from "gatsby";
import { jsx, Flex, Button } from "theme-ui";

const TagsRelated = ({ tags }) => (
	<Flex sx={{ flexWrap: "wrap" }}>
		{tags.map((t, index) => (
			<Button
				as={GatsbyLink}
				variant="subdued"
				key={index}
				to={`/tags/${_.kebabCase(t)}`}
				mr={[2]}
				mb={[2]}
			>
				{t}
			</Button>
		))}
	</Flex>
);

export default TagsRelated;
