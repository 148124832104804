/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";
import Menu from "../../data/menu.json";
import { Flex, NavLink } from "theme-ui";

const NavItem = (props) => {
	function isExternal(url) {
		if (url.includes("https://")) {
			return (
				<NavLink
					as="a"
					href={props.item.url}
					title={props.item.label}
					target="_blank"
					rel="noopener"
					{...props}
				>
					{props.item.label}
				</NavLink>
			);
		} else {
			return (
				<NavLink
					as={Link}
					exact
					to={props.item.url}
					activeClassName="active"
					title={props.item.label}
					{...props}
					sx={{ "&[aria-current = page]": { color: "active" } }}
				>
					{props.item.label}
				</NavLink>
			);
		}
	}

	return isExternal(props.item.url);
};

const Nav = () => (
	<Flex as="nav">
		{Menu.map((item, index) => (
			<NavItem
				key={index}
				item={item}
				mr={index === Menu.length - 1 ? 0 : [2, 3, 3, 4]}
				sx={
					({ fontWeight: "400" },
					{ display: index <= 2 ? ["none", "none", "inherit"] : ["inherit"] })
				}
			/>
		))}
	</Flex>
);

export default Nav;
