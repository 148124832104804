/** @jsx jsx */
import { jsx, Grid, Box, Text, Divider } from "theme-ui";

import { Link } from "gatsby";
import { Post } from "../components";

const PostList = ({ posts, isTag }) => {
	const hasPosts = posts.length > 0;

	return (
		<Box>
			<Text mr={3} py={2}>
				{posts.length} article{posts.length === 1 ? "" : "s"}
			</Text>
			{hasPosts && (
				<Grid columns={[1, 2, 3]} gap={[3, 5]}>
					{posts.map((post, index) => {
						if (process.env.NODE_ENV !== "development") {
							if (post.node.frontmatter.draft !== true) {
								return (
									<Post post={post.node} key={index.toString()} tabIndex={0} />
								);
							}
						} else {
							return (
								<Post post={post.node} key={index.toString()} tabIndex={0} />
							);
						}
					})}
				</Grid>
			)}
			{!hasPosts && (
				<Box>
					<Text
						sx={{
							fontSize: 4,
						}}
					>
						We could not find any posts!
					</Text>
				</Box>
			)}
			{isTag && (
				<Box py={[3, 4]}>
					<Divider mb={[2, 3]} />
					<Link to="/posts/">View all articles</Link>
				</Box>
			)}
		</Box>
	);
};

export default PostList;
